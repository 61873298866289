export default class Parallax {
  constructor(className, scrollbar) {
    this.windowHeight = window.innerHeight
    this.target = document.querySelectorAll(`.${className}`)
    this.targetObjs = []
    this.targetData = []
    this.observer = ''
    this.scrollbar = scrollbar
    this.threshold = -350

    this.target.forEach(element => {
      if (element.getAttribute('data-active') !== 'true') {
        element.setAttribute('data-active', 'false')
      }
      this.targetObjs.push({
        selector: element
      })
    })

    this.getData()

    this.initObserver()
    this.initEvents()

    this.updateElements()
  }

  initObserver() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.getAttribute('data-children') == 'true') {
            entry.target.parentElement.setAttribute('data-active', 'true')
          } else {
            entry.target.setAttribute('data-active', 'true')
          }
        } else if (!entry.isIntersecting) {
          if (entry.target.getAttribute('data-children') == 'true') {
            entry.target.parentElement.setAttribute('data-active', 'false')
          } else {
            entry.target.setAttribute('data-active', 'false')
          }
        }
      })
    })

    this.targetObjs.forEach(target => {
      if (target.selector.getAttribute('data-children') == 'true') {
        this.observer.observe(target.selector.firstElementChild)
      } else {
        this.observer.observe(target.selector)
      }
    })
  }

  checkVisible(elm, threshold, mode) {
    threshold = threshold || 0;
    mode = mode || 'visible';

    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    var above = rect.bottom - threshold < 0;
    var below = rect.top - viewHeight + threshold >= 0;

    return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
  }

  /**
   * function initEvents
   * Add event listener
   */
  initEvents() {
    // window.addEventListener('scroll', evt => this.updateElements(evt))
    this.scrollbar.addListener((s) => this.checkIfElementsAreInScreen(s))
    this.scrollbar.addListener((s) => this.updateElements(s))
  }

  checkIfElementsAreInScreen() {
    this.targetObjs.forEach(target => {
      let testedTarget = ''

      if (target.selector.getAttribute('data-children') == 'true') {
        testedTarget = target.selector.firstElementChild
      } else {
        testedTarget = target.selector
      }

      if (this.checkVisible(testedTarget, this.threshold)) {
        if (testedTarget.getAttribute('data-children') == 'true') {
          testedTarget.parentElement.setAttribute('data-active', 'true')
        } else {
          testedTarget.setAttribute('data-active', 'true')
        }
      } else {
        if (testedTarget.getAttribute('data-children') == 'true') {
          testedTarget.parentElement.setAttribute('data-active', 'false')
        } else {
          testedTarget.setAttribute('data-active', 'false')
        }
      }
    })
  }

  /**
   * function updateElements
   * Update translateY value for all targeted element
   * @return void
   */
  updateElements() {
    for (let i = 0; i < this.targetObjs.length; i++) {
      if (this.targetObjs[i].selector.getAttribute('data-active') == 'true') {
        if (this.targetData[i].dataDirection == 'down') {
          var offset = this.getTargetPos(this.targetObjs[i].selector)*-1
        } else {
          var offset = this.getTargetPos(this.targetObjs[i].selector)
        }

        if (this.targetData[i].hadTransform){
          let translateX = this.targetData[i].transform.X
          let translateY = ( parseInt(this.targetData[i].transform.Y) + offset ) * (this.targetData[i].dataSpeed/100)

          this.targetObjs[i].selector.style.transform = 'translate3d('+ translateX + 'px, ' + translateY + 'px, 0)'

        } else {
          this.targetObjs[i].selector.style.transform = 'translate3d(0, '+ (offset * (this.targetData[i].dataSpeed/100)) +'px, 0)'
        }
      }
    }
  }

  /**
   * function getData
   * Get the value of data-speed for all target element
   * @return this
   */
  getData() {
    var arr = []

    for (let i = 0; i < this.targetObjs.length; i++) {
      var dataSpeed = this.targetObjs[i].selector.getAttribute('data-speed') ? this.targetObjs[i].selector.getAttribute('data-speed') : 5
      var dataDirection = this.targetObjs[i].selector.getAttribute('data-direction') ? this.targetObjs[i].selector.getAttribute('data-direction') : 'down'

      var transformMatrix = window.getComputedStyle(this.targetObjs[i].selector).transform

      var hasTransform = transformMatrix != 'none' ? true : false

      var input = {
        dataSpeed: dataSpeed,
        dataDirection: dataDirection,
        hadTransform: hasTransform
      }

      if (hasTransform) {
        const transform = this.transformMatrixToPx(transformMatrix)
        input.transform = transform
      }

      arr.push(input)
    }

    this.targetData = arr
  }

  /**
   * function getTargetPos
   * @param object   Element targeted
   * @return int     Position of the element relatively from the viewport
   */
  getTargetPos(elem) {
    const rect = elem.getBoundingClientRect()
    return rect.top + (rect.height / 2) - (this.windowHeight / 2)
  }

  updateDataElements() {
    this.getData()
    this.updateElements()
  }

  /**
   * function transformMatrixToPx
   * Transform the matrix that we get with elem.getComputedStyle and transform it to px value
   * @param   matrix   string
   * @return   object
   */
  transformMatrixToPx(matrix) {
    var tr = matrix.split('(')[1],
      tr = tr.split(')')[0],
      tr = tr.split(',')

    return {
      X: tr[4],
      Y: tr[5]
    }
  }
}
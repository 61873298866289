import helpers from '../helpers'
export default class Shop {

  init(route, direction) {
    const $shop = document.querySelector('.shop')
    TweenMax.to($shop, 1, {
        y: 0,
        ease: Circ.easeInOut,
        onComplete: () => helpers.clearOldDom(route)
      }
    )

    this.showTitle()
    this.showText()
    this.showPurshase()
    this.showRectangle()
    this.showImage()
  }

  showTitle() {
    const splitTitle = new SplitText('.shop--title', { type: 'chars' })
    TweenMax.staggerFrom(splitTitle.chars, .5, { y: '100%', ease: Power1.easeOut, delay: 1 }, .03)
  }

  showText() {
    const splitText = new SplitText('.shop--text', { type: 'lines' })
    TweenMax.staggerFrom(splitText.lines, .5, { y: '100%', opacity: 0, ease: Power1.easeOut, delay: 1.2 }, .03)
  }

  showPurshase() {
    const $purshase = document.querySelectorAll('.shop--purchase')
    TweenMax.staggerFrom($purshase, .5, { y: '100%', opacity: 0, ease: Power1.easeOut, delay: 1.2 })
  }

  showRectangle() {
    const $rectangle = document.querySelectorAll('.shop--purchase svg > rect')
    TweenMax.to($rectangle, 1.4,
      { strokeDashoffset: '1360px', ease: Power1.easeOut, delay: 1.7 }
    )
  }

  showImage() {
    const $image = document.querySelectorAll('.shop--image')
    TweenMax.staggerFrom($image, 1, { y: '100px', opacity: 0, ease: Power1.easeOut, delay: 1.2 })
  }
}
import Intro from "./Intro";
import Header from "./Header";
import Asides from "./Asides";
import Video from "./Video";
import config from "../config.json";

export default class Router {
  constructor(viewControllers, cursor) {
    this.viewControllers = viewControllers;
    this.cursor = cursor;
    this.pathArray = window.location.pathname.split("/");
    this.current = this.pathArray[1] || "home";
    this.backgroundAudio = document.querySelector(".background-sound");
    this.offAudio = document.querySelector(".background-sound--off");
    this.header = "";
    this.asides = "";
    this.alreadyLoadLastChapter = false;
    this.video = new Video();

    window.addEventListener("popstate", () => {
      this.route(this.pullRoute());
    });

    if (sessionStorage.getItem("intro")) {
      this.introCallback();
    } else {
      this.intro = new Intro();
      this.cursor.events();
      this.intro.init(() => this.introCallback());
      sessionStorage.setItem("intro", true);
    }

    //debug
    // this.intro = new Intro()
    // this.cursor.events()
    // this.intro.init(() => this.introCallback())
    // sessionStorage.setItem('intro', true)

    this.video.init();
    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  introCallback() {
    this.header = new Header();
    this.asides = new Asides();
    this.init();
  }

  handleClickEvent(e) {
    e.preventDefault();

    const direction = e.currentTarget.dataset.direction;
    const route = e.currentTarget.dataset.url;

    if (this.pullRoute() === route) {
      return false;
    }

    if (
      (this.pullRoute() === "" || this.pullRoute() === "home") &&
      (route === "" || route === "home")
    ) {
      return false;
    }

    this.route(route, direction);
    this.pushRoute(route);
  }

  initEventListenerOnLinks() {
    let $links = document.querySelectorAll(".route-link");

    $links.forEach(($link) => {
      $link.addEventListener("click", this.handleClickEvent);
    });
  }

  removeEventListenerOnLinks() {
    let $links = document.querySelectorAll(".route-link");

    $links.forEach(($link) => {
      $link.removeEventListener("click", this.handleClickEvent);
    });
  }

  getFromPreviousOrNext(next) {
    if (
      config.route[this.current].previous &&
      config.route[this.current].previous.slug === next
    ) {
      return "previous";
    } else {
      return "next";
    }
  }

  updateAsideNavs() {
    const asideLeft = document.querySelector(".aside-left");
    const asideLeftLink = asideLeft.querySelector(".aside-left--link");
    const asideLeftText = asideLeft.querySelector(".aside-left--home--text");
    const asideLeftDot = asideLeft.querySelector(".aside-left--home--dot");

    const asideRight = document.querySelector(".aside-right--chapter");
    const asideRightText = document.querySelector(
      ".aside-right--chapter--text"
    );
    const asideRightLink = document.querySelector(".aside-right--link");
    const asideRightDot = document.querySelector(".aside-right--chapter--dot");

    const asideTopText = document.querySelector(".header--shop--text");
    const asideTopTextCurrent = document.querySelectorAll(
      ".header--title--text"
    )[1];
    const asideTopLink = asideTopText.querySelector("a");

    // <!-- <div class="header--prevention--text"><a class="route-link link cursor-hover block" data-url="prevention" href="/prevention" title="Découvrir le projet">Prévention</a></div> -->

    if (config.route[this.current].previous) {
      asideLeftLink.style.display = "block";
      const timeline = new TimelineMax();
      timeline.to(asideLeftText, 1, { y: -50, ease: Power3.easeOut });
      timeline.to(asideLeftDot, 0.5, { scale: 0, ease: Power3.easeOut }, 0);
      timeline.to(asideLeftText, 1, { y: 1, ease: Power3.easeOut });
      timeline.to(asideLeftDot, 1, { scale: 1, ease: Power3.easeOut }, 1);
      setTimeout(() => {
        asideLeftText.innerHTML = config.route[this.current].previous.string;
        asideTopTextCurrent.innerHTML =
          config.route[this.current].current.string;
      }, 800);

      asideLeftLink.setAttribute(
        "href",
        `/${config.route[this.current].previous.slug}`
      );
      asideLeftLink.setAttribute(
        "data-url",
        `${config.route[this.current].previous.slug}`
      );
    } else {
      TweenMax.to(asideLeftText, 1, { y: -50, ease: Power3.easeOut });
      TweenMax.to(asideLeftDot, 0.5, { scale: 0, ease: Power3.easeOut });
      setTimeout(() => {
        asideLeftLink.style.display = "none";
      }, 1000);
    }

    if (config.route[this.current].top) {
      asideTopText.style.display = "flex";

      setTimeout(() => {
        asideTopLink.innerHTML = config.route[this.current].top.string;
        asideTopTextCurrent.innerHTML =
          config.route[this.current].current.string;
        asideTopLink.setAttribute(
          "data-content",
          `${config.route[this.current].top.string}`
        );
        asideTopLink.setAttribute(
          "href",
          `/${config.route[this.current].top.slug}`
        );
        asideTopLink.setAttribute(
          "data-url",
          `${config.route[this.current].top.slug}`
        );
        asideTopLink.setAttribute(
          "title",
          `${config.route[this.current].top.string}`
        );
      }, 800);
    } else {
      asideTopText.style.display = "none";
    }

    if (config.route[this.current].next) {
      asideRight.style.display = "block";

      const timeline = new TimelineMax();
      timeline.to(asideRightText, 1, { y: -40, ease: Power3.easeOut });
      timeline.to(asideRightDot, 0.5, { scale: 0, ease: Power3.easeOut }, 0);
      timeline.to(asideRightText, 1, { y: 1, ease: Power3.easeOut });
      timeline.to(asideRightDot, 1, { scale: 1, ease: Power3.easeOut }, 1.1);
      setTimeout(() => {
        asideRightLink.innerHTML = config.route[this.current].next.string;
      }, 500);

      asideRightLink.setAttribute(
        "href",
        `/${config.route[this.current].next.slug}`
      );
      asideRightLink.setAttribute(
        "data-url",
        `${config.route[this.current].next.slug}`
      );
      asideRightLink.setAttribute(
        "data-content",
        `${config.route[this.current].next.string}`
      );
    } else {
      TweenMax.to(asideRightText, 1, { y: -20, ease: Power3.easeOut });
      TweenMax.to(asideRightDot, 0.5, { scale: 0, ease: Power3.easeOut });
      setTimeout(() => {
        asideRightLink.innerHTML = config.route[this.current].next.string;
        asideRightLink.setAttribute(
          "href",
          `/${config.route[this.current].next.slug}`
        );
        asideRightLink.setAttribute(
          "data-url",
          `${config.route[this.current].next.slug}`
        );
        asideRightLink.setAttribute(
          "data-content",
          `${config.route[this.current].next.string}`
        );

      }, 1000);
    }
  }

  ajax(route, direction) {
    this.isRequesting = true;

    const url = `/views/${route}.html`;
    const http = new XMLHttpRequest();

    http.onreadystatechange = () => {
      if (http.readyState == 4 && http.status == 200) {
        const newDiv = document.createElement("div");
        if (route.startsWith("chapter-")) {
          newDiv.classList.add(route);
          newDiv.classList.add("smooth-scroll");
        }
        newDiv.innerHTML = http.responseText;
        document.querySelector(".app").appendChild(newDiv);

        this.cursor.events();

        // Current is not updated yet

        const from = this.getFromPreviousOrNext(route);

        this.pathArray = window.location.pathname.split("/");
        this.current = this.pathArray[1] || "home";

        // Current is updated already

        this.updateAsideNavs();
        this.removeEventListenerOnLinks();
        this.initEventListenerOnLinks();

        if (this.currentView && this.currentView.leave) {
          this.currentView.leave(direction);
        }

        switch (route) {
          case "home":
            this.currentView = this.viewControllers.home;
            this.currentView.init(route, direction);
            break;
          case "chapter-1":
            this.currentView = this.viewControllers.chapters;
            this.currentView.init(route, from, direction);
            break;
          case "chapter-2":
            this.currentView = this.viewControllers.chapters;
            this.currentView.init(route, from, direction);
            break;
          case "chapter-3":
            this.currentView = this.viewControllers.chapters;
            this.currentView.init(route, from, direction);

            if (!this.alreadyLoadLastChapter) {
              this.video.linkInChapter();
              this.alreadyLoadLastChapter = true;
            }
            break;
          case "shop":
            this.currentView = this.viewControllers.shop;
            this.currentView.init(route, direction);
            break;
          case "prevention":
            this.currentView = this.viewControllers.contact;
            this.currentView.init(route, direction);
            break;
          case "project":
            this.currentView = this.viewControllers.project;
            this.currentView.init(route, direction);
            break;
        }
      }
    };

    http.open("GET", url, true);
    http.send();
  }

  init() {
    this.route(this.pullRoute());
  }

  pullRoute() {
    return window.location.href.split("/")[3];
  }

  pushRoute(route) {
    history.pushState(null, null, route);
  }

  route(route, direction) {
    if (route === "" || route === "home") {
      this.backgroundAudio.currentTime = 0;
      this.offAudio.currentTime = 0;
      this.backgroundAudio.play();
      this.offAudio.play();
    } else {
      this.backgroundAudio.play();
      this.offAudio.pause();
    }

    switch (route) {
      case "":
        this.ajax("home");
        break;
      case "home":
        this.ajax(route, direction);
        break;
      case "chapter-1":
        this.ajax(route, direction);
        document.body.classList.remove("no-scroll");
        break;
      case "chapter-2":
        this.ajax(route, direction);
        document.body.classList.remove("no-scroll");
        break;
      case "chapter-3":
        this.ajax(route, direction);
        document.body.classList.remove("no-scroll");
        break;
      case "film":
        this.ajax(route, direction);
        break;
      case "shop":
        this.ajax(route, direction);
        break;
      case "prevention":
        this.ajax(route, direction);
        break;
      case "project":
        this.ajax(route, direction);
        break;
      default:
        this.ajax("404", direction);
        break;
    }
  }
}

export default class Cursor {

  constructor() {
    const variables = getComputedStyle(document.body)
    const color = variables.getPropertyValue('--red')
    const circleSvg = '<svg class="circle" height="44" width="44"><circle cx="22" cy="22" r="20"></svg>'
    const crossSvg = '<svg class="cross" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><path class="path line" fill="none" stroke-width="4" stroke-miterlimit="10" d="M34.4 37.9l61.4 54.4"/><path class="path line" fill="none" stroke-width="4" stroke-miterlimit="10" d="M95.8 38L34.4 92.2"/></svg>'

    this.$cursor = document.createElement('div')
    this.$cursor.classList.add('js-cursor')

    this.$cursor.insertAdjacentHTML('beforeend', circleSvg)
    this.$cursor.insertAdjacentHTML('beforeend', crossSvg)

    this.arrowLeft =  document.createElement('div');
    this.arrowLeft.classList.add('arrow-left')

    this.arrowRight =  document.createElement('div');
    this.arrowRight.classList.add('arrow-right')

    this.$cursor.appendChild(this.arrowLeft)
    this.$cursor.appendChild(this.arrowRight)

    document.body.appendChild(this.$cursor)

    this.$pointer = document.createElement('div')
    this.$pointer.classList.add('js-pointer')
    this.$pointer.style.cssText = `
      width: 10px;
      height: 10px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100005;
      background: ${color};
      border-radius: 50%;
      transform: translateX(50vw) translateY(50vh);
      pointer-events: none;
    `
    document.body.appendChild(this.$pointer)

    this.animInCursor()
    this.render(this.$pointer)
  }

  animInCursor() {
    const cursorCircleDom = this.$cursor.querySelector('svg circle')

    const timeline = new TimelineMax({ delay: .5 })
    timeline.to(cursorCircleDom, 1.5, { strokeDashoffset: '370px', ease: Circ.easeInOut })
    timeline.from(this.$pointer, 0.5, { autoAlpha: 0, ease: Power1.easeOut })
  }

  render($pointer) {
    window.addEventListener('mousemove', (event) => {
      TweenMax.to(this.$cursor, .25, { x: event.clientX - 20, y: event.clientY - 20, ease: Power1.easeOut })
      $pointer.style.transform = `translateX(${event.clientX - 5}px) translateY(${event.clientY - 5}px)`
    })
  }

  events() {
    const $interractions = document.querySelectorAll('.cursor-hover')
    const $dragInteraction = document.querySelectorAll('.chapter--symptoms ul')

    $interractions.forEach($interraction => {
      $interraction.style.cursor = 'none'
      $interraction.addEventListener('mouseover', () => { this.mouseoverHandler() })
      $interraction.addEventListener('mouseout', () => { this.mouseoutHandler() })
      window.addEventListener('mouseup', () => { this.mouseupHandler() })
    })

    $dragInteraction.forEach(interaction => {
      interaction.style.cursor = 'none'
      interaction.addEventListener('mouseover', () => { this.dragMouseoverHandler() })
      interaction.addEventListener('mouseout', () => { this.dragMouseoutHandler() })
      interaction.addEventListener('mousedown', () => { this.dragMousedownHandler() })
      interaction.addEventListener('mouseup', () => { this.dragMouseupHandler() })
    })
  }

  mouseoverHandler() {
    TweenMax.to(this.$cursor, .2, { scale: 3, ease: Power1.easeOut });
    TweenMax.to(this.$cursor.querySelector('svg circle'), .2, { strokeWidth: '.5px', ease: Power1.easeOut });
  }

  mouseoutHandler() {
    TweenMax.to( this.$cursor, .2, { scale: 1, ease: Power1.easeOut })
    TweenMax.to( this.$cursor.querySelector('svg circle'), .2, { strokeWidth: '2px', ease: Power1.easeOut })
  }

  mouseupHandler() {
    TweenMax.to( this.$cursor, .2, { scale: 1, ease: Power1.easeOut })
    TweenMax.to( this.$cursor.querySelector('svg circle'), .2, { strokeWidth: '2px', ease: Power1.easeOut })
  }

  dragMouseoverHandler() {
    TweenMax.to(this.arrowLeft, .2, { x: -7, autoAlpha: 1, ease: Power1.easeOut });
    TweenMax.to(this.arrowRight, .2, { x: 7, autoAlpha: 1, ease: Power1.easeOut });
  }
  
  dragMouseoutHandler() {
    TweenMax.to(this.arrowLeft, .2, { x: 0, autoAlpha: 0, ease: Power1.easeOut });
    TweenMax.to(this.arrowRight, .2, { x: 0, autoAlpha: 0, ease: Power1.easeOut });
  }
  
  dragMousedownHandler() {
    TweenMax.to(this.arrowLeft, .2, { x: -25, ease: Power1.easeOut });
    TweenMax.to(this.arrowRight, .2, { x: 25, ease: Power1.easeOut });
  }
  
  dragMouseupHandler() {
    TweenMax.to(this.arrowLeft, .2, { x: -7, ease: Power1.easeOut });
    TweenMax.to(this.arrowRight, .2, { x: 7, ease: Power1.easeOut });  
  }
}
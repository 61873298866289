import helpers from "../helpers";
import Parallax from "../vendor/parallax";
import Scrollbar from "smooth-scrollbar";
import Slider from "./Sliders";
import transitionSettings from "../utils/transitionSettings";

class Chapters {
  init(route, from, direction) {
    this.route = route;
    this.from = from;
    this.direction = direction;
    this.$chapter = document.querySelectorAll(`.${this.route}`);
    this.$smooth = document.querySelectorAll(".smooth-scroll");
    const $triggers = document.querySelectorAll(
      `.${this.route} .chapter--part`
    );
    this.transitionEl = document.querySelector(".transition");

    this.animations = {};
    this.images = {
      hand: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-hand .chapter--image__tag`
        ),
      },
      point: {
        active: false,
        parent: document.querySelector(`.${this.route} .image-point`),
        selector: document.querySelector(
          `.${this.route} .image-point .chapter--image__tag`
        ),
      },
      bol: {
        selector: document.querySelector(`.${this.route} .image-bol--top`),
      },
      brain: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-brain .chapter--image__tag--hand`
        ),
      },
      balance: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-balance .chapter--image__tag--rotate`
        ),
      },
      heart: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-heart .chapter--image__tag`
        ),
      },
      wound: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-wound .chapter--image__tag`
        ),
      },
      bag: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-bag .chapter--image__tag`
        ),
      },
      zoom: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-zoom .chapter--image__tag`
        ),
      },
      blueMedicine: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-blue-medicine .chapter--image__tag--hand`
        ),
      },
      tools: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-tools .chapter--image__tag--hand`
        ),
      },
      introHand: {
        active: false,
        selector: document.querySelector(
          `.${this.route} .image-intro--hand .chapter--image__tag`
        ),
      },
    };
    this.setup();
    this.playTransition();
    this.show();
    this.intersectionObservers($triggers);
    this.observerFooter();
  }

  setup() {
    this.setStyleDependingOnPosition();
    this.setupContentHeight();
    this.setupSmooth();
    this.introHandChapter2();
    this.setupSlider();
    this.setupImagesObservers();
  }

  setupContentHeight() {
    const $chapterScreenWidth = document.querySelectorAll(
      `.${this.route} .chapter--screen-width`
    );

    this.contentHeight =
      $chapterScreenWidth[$chapterScreenWidth.length - 1].clientHeight;
    this.$chapter[
      this.$chapter.length - 1
    ].style.height = `${this.contentHeight}px`;
  }

  setStyleDependingOnPosition() {
    if (this.from === "previous") {
      this.$chapter[this.$chapter.length - 1].classList.add("chapter-previous");
    }
  }

  setupSmooth() {
    this.$smooth.forEach(($smooth) => {
      $smooth.style.position = "absolute";
    });

    this.scrollbar = Scrollbar.init(
      document.querySelector(`.${this.route}.smooth-scroll`)
    );
    this.parallax = new Parallax("parallax", this.scrollbar);
  }

  introHandChapter2() {
    if (this.images.introHand.selector) {
      this.animations.introHand = new TimelineMax();
      this.animations.introHand.pause();
      this.animations.introHand.from(this.images.introHand.selector, 2, {
        rotation: 20,
        ease: Expo.easeOut,
      });
      this.animations.introHand.to(this.images.introHand.selector, 2, {
        rotation: -20,
        ease: Power2.easeIn,
      });
    }
  }

  setupSlider() {
    if (this.route === "chapter-2") {
      this.$sliders = new Slider(".chapter--slider");
    }
  }

  setupImagesObservers() {
    if (this.images.hand.selector) {
      this.handleHandObserver();
    }
    if (this.images.point.selector) {
      this.handlePointObserver();
    }
    if (this.images.brain.selector) {
      this.handleBrainObserver();
    }
    if (this.images.balance.selector) {
      this.handleBalanceObserver();
    }
    if (this.images.heart.selector) {
      this.handleHeartObserver();
    }
    if (this.images.wound.selector) {
      this.handleWoundObserver();
    }
    if (this.images.bag.selector) {
      this.handleBagObserver();
    }
    if (this.images.zoom.selector) {
      this.handleZoomObserver();
    }
    if (this.images.blueMedicine.selector) {
      this.handleBlueMedicineObserver();
    }
    if (this.images.tools.selector) {
      this.handleToolsObserver();
    }
    if (this.images.introHand.selector) {
      this.handleIntroHandObserver();
    }
  }

  leave(direction) {
    const duration = transitionSettings.duration;

    switch (direction) {
      case "left":
        TweenLite.fromTo(
          this.$chapter,
          duration,
          { x: 0 },
          { x: 300, ease: transitionSettings.ease },
          0
        );
        break;
      case "right":
        TweenLite.fromTo(
          this.$chapter,
          duration,
          { x: 0 },
          { x: -300, ease: transitionSettings.ease },
          0
        );
        break;
      case "top":
        TweenLite.fromTo(
          this.$chapter,
          duration,
          { y: 0 },
          { y: 300, ease: transitionSettings.ease },
          0
        );
        break;
      case "bottom":
        TweenLite.fromTo(
          this.$chapter,
          duration,
          { y: 0 },
          { y: -300, ease: transitionSettings.ease },
          0
        );
        break;
      default:
        TweenLite.fromTo(
          this.$chapter,
          duration,
          { y: 0 },
          { y: -300, ease: transitionSettings.ease },
          0
        );
        break;
    }
  }

  playTransition() {
    const duration = transitionSettings.duration;
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (!this.direction && this.from === "previous") {
      this.direction = "left";
    }
    if (!this.direction && this.from === "next") {
      this.direction = "right";
    }

    let timeline = new TimelineLite({
      onComplete: () => {
        this.onInitialAnimCompleted();
      },
    });

    //debug
    // timeline.timeScale(0.5)

    timeline.set(this.transitionEl, { display: "block" });

    switch (this.direction) {
      case "left":
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: -width, y: 0 },
          { x: width, ease: transitionSettings.ease }
        );
        break;
      case "right":
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: width, y: 0 },
          { x: -width, ease: transitionSettings.ease }
        );
        break;
      case "top":
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: 0, y: -height },
          { y: height, ease: transitionSettings.ease }
        );
        break;
      case "bottom":
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: 0, y: height },
          { y: -height, ease: transitionSettings.ease }
        );
        break;
      default:
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: 0, y: height },
          { y: -height, ease: transitionSettings.ease }
        );
        break;
    }

    timeline.set(
      this.$chapter,
      { width: "100vw", ease: Circ.easeInOut, delay: 0.5 },
      0.3
    );
    timeline.set(this.transitionEl, { display: "none" });
  }

  onInitialAnimCompleted() {
    helpers.clearOldDom(this.route);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.$smooth.forEach(($smooth) => {
      $smooth.style.position = "relative";
    });
    this.$chapter[this.$chapter.length - 1].classList.add("chapter-static");
  }

  show() {
    // Intro img
    TweenMax.from(
      `.${this.route} .image-intro--chest .chapter--image__tag, .${this.route} .image-intro--red-medicine .chapter--image__tag`,
      1.5,
      { y: "100px", ease: Power1.easeOut }
    );

    // IntroHand
    if (this.images.introHand.selector) {
      const boundingClientRect = this.images.introHand.selector.getBoundingClientRect();
      const imageRelativeToScreen = Math.abs(
        boundingClientRect.y - window.innerHeight
      );

      const min = 0;
      const max = boundingClientRect.height + window.innerHeight;

      const range = max - min;
      const ratio = imageRelativeToScreen / range;

      setTimeout(() => {
        this.animations.introHand.tweenTo(ratio + 1);
      }, 500);
    }

    // Intro
    const split = new SplitText(`.${this.route} .chapter--number`, {
      type: "chars",
    });
    TweenMax.staggerFrom(
      split.chars,
      0.5,
      { y: "100%", ease: Power1.easeOut, delay: 1 },
      0.03
    );

    const $bar = document.querySelector(`.${this.route} .chapter--name--bar`);
    TweenMax.to($bar, 0.5, {
      transform: "scaleX(100%)",
      ease: Power1.easeOut,
      delay: 1.5,
    });

    const $text = document.querySelectorAll(
      `.${this.route} .chapter--name--text`
    );
    TweenMax.staggerFrom([$text], 0.5, {
      y: "110%",
      ease: Power1.easeOut,
      delay: 0.8,
    });
  }

  intersectionObservers($triggers) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target);

            entry.target.style.visibility = "visible";

            const $title = entry.target.querySelector(".chapter--subtitle");
            const splitTitle = new SplitText($title, { type: "chars" });
            TweenMax.staggerFrom(
              splitTitle.chars,
              0.5,
              { y: "100%", ease: Power1.easeOut },
              0.03
            );

            const $texts = entry.target.querySelectorAll(".chapter--text");
            $texts.forEach(($text) => {
              const split = new SplitText($text, { type: "lines" });
              TweenMax.staggerFrom(
                split.lines,
                0.5,
                { y: "30px", opacity: 0, ease: Power1.easeOut, delay: 0.3 },
                0.03
              );
            });
          }
        });
      },
      {
        // rootMargin: "100px",
      }
    );

    $triggers.forEach(($trigger) => {
      $trigger.style.visibility = "hidden";
      observer.observe($trigger);
    });
  }

  observerFooter() {
    const chapterNext = document.querySelectorAll(
      `.${this.route} .chapter--next`
    );

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
          const $textChap = entry.target.querySelector(
            ".chapter--next .word-chapter"
          );
          const split = new SplitText($textChap, { type: "chars" });
          TweenMax.staggerFrom(
            split.chars,
            0.5,
            { y: "110%", ease: Power1.easeOut, delay: 0.1 },
            0.02
          );

          const $textNext = entry.target.querySelector(
            ".chapter--next .word-next"
          );
          const split2 = new SplitText($textNext, { type: "chars" });
          TweenMax.staggerFrom(
            split2.chars,
            0.5,
            { y: "110%", ease: Power1.easeOut, delay: 0.2 },
            0.02
          );
          split.chars.forEach((char, i) => {
            char.style.transitionDelay = i * 0.02 + "s";
          });
          split2.chars.forEach((char, i) => {
            char.style.transitionDelay = i * 0.02 + 0.14 + "s";
          });
        }
      });
    });

    chapterNext.forEach((chapter) => {
      observer.observe(chapter);
    });
  }

  handleHandObserver() {
    this.animations.hand = new TimelineMax();
    this.animations.hand.pause();
    this.animations.hand.to(this.images.hand.selector, 1.5, {
      rotation: 25,
    });

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.hand,
        this.images.hand.selector,
        this.images.hand.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.hand.selector.style.willChange = "transform";
        this.images.hand.active = true;
      } else {
        this.images.hand.selector.style.willChange = "auto";
        this.images.hand.active = false;
      }
    });
    observer.observe(this.images.hand.selector);
  }

  handlePointObserver() {
    const bolBoundingClientRect = this.images.bol.selector.getBoundingClientRect();
    const handBoundingClientRect = this.images.hand.selector.getBoundingClientRect();
    const verticalTranslate =
      bolBoundingClientRect.x -
      handBoundingClientRect.x -
      handBoundingClientRect.width +
      220 +
      bolBoundingClientRect.width / 2;

    this.animations.point = new TimelineMax();
    this.animations.point.pause();
    this.animations.point.to(
      this.images.point.selector,
      1.5,
      {
        x: `${verticalTranslate}px`,
        ease: Power1.easeIn,
      },
      "first-step"
    );
    this.animations.point.to(
      this.images.point.selector,
      1.5,
      {
        y: "720px",
        ease: Power1.easeIn,
      },
      "first-step"
    );

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.point,
        this.images.point.parent,
        this.images.point.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.point.active = true;
      } else {
        this.images.point.active = false;
      }
    });
    observer.observe(this.images.point.selector);
  }

  handleBrainObserver() {
    this.animations.brain = new TimelineMax();
    this.animations.brain.pause();
    this.animations.brain.from(this.images.brain.selector, 1.5, {
      y: "-290px",
    });

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.brain,
        this.images.brain.selector,
        this.images.brain.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.brain.selector.style.willChange = "transform";
        this.images.brain.active = true;
      } else {
        this.images.brain.selector.style.willChange = "auto";
        this.images.brain.active = false;
      }
    });
    observer.observe(this.images.brain.selector);
  }

  handleBalanceObserver() {
    this.animations.balance = new TimelineMax();
    this.animations.balance.pause();
    this.animations.balance.from(this.images.balance.selector, 1.5, {
      rotation: -25,
    });

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.balance,
        this.images.balance.selector,
        this.images.balance.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.balance.selector.style.willChange = "transform";
        this.images.balance.active = true;
      } else {
        this.images.balance.selector.style.willChange = "auto";
        this.images.balance.active = false;
      }
    });
    observer.observe(this.images.balance.selector);
  }

  handleHeartObserver() {
    this.animations.heart = new TimelineMax();
    this.animations.heart.pause();
    this.animations.heart.from(this.images.heart.selector, 1.5, {
      rotation: -20,
    });

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.heart,
        this.images.heart.selector,
        this.images.heart.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.heart.selector.style.willChange = "transform";
        this.images.heart.active = true;
      } else {
        this.images.heart.selector.style.willChange = "auto";
        this.images.heart.active = false;
      }
    });
    observer.observe(this.images.heart.selector);
  }

  handleWoundObserver() {
    this.animations.wound = new TimelineMax();
    this.animations.wound.pause();
    this.animations.wound.from(this.images.wound.selector, 1.5, {
      rotation: -20,
    });

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.wound,
        this.images.wound.selector,
        this.images.wound.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.wound.selector.style.willChange = "transform";
        this.images.wound.active = true;
      } else {
        this.images.wound.selector.style.willChange = "auto";
        this.images.wound.active = false;
      }
    });
    observer.observe(this.images.wound.selector);
  }

  handleBagObserver() {
    this.animations.bag = new TimelineMax();
    this.animations.bag.pause();
    this.animations.bag.from(this.images.bag.selector, 1.5, {
      rotation: -15,
    });

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.bag,
        this.images.bag.selector,
        this.images.bag.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.bag.selector.style.willChange = "transform";
        this.images.bag.active = true;
      } else {
        this.images.bag.selector.style.willChange = "auto";
        this.images.bag.active = false;
      }
    });
    observer.observe(this.images.bag.selector);
  }

  handleZoomObserver() {
    this.animations.zoom = new TimelineMax();
    this.animations.zoom.pause();
    this.animations.zoom.from(this.images.zoom.selector, 1.5, {
      rotation: 20,
    });

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.zoom,
        this.images.zoom.selector,
        this.images.zoom.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.zoom.selector.style.willChange = "transform";
        this.images.zoom.active = true;
      } else {
        this.images.zoom.selector.style.willChange = "auto";
        this.images.zoom.active = false;
      }
    });
    observer.observe(this.images.zoom.selector);
  }

  handleBlueMedicineObserver() {
    this.animations.blueMedicine = new TimelineMax();
    this.animations.blueMedicine.pause();
    this.animations.blueMedicine.to(this.images.blueMedicine.selector, 1.5, {
      y: "290px",
    });

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.blueMedicine,
        this.images.blueMedicine.selector,
        this.images.blueMedicine.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.blueMedicine.selector.style.willChange = "transform";
        this.images.blueMedicine.active = true;
      } else {
        this.images.blueMedicine.selector.style.willChange = "auto";
        this.images.blueMedicine.active = false;
      }
    });
    observer.observe(this.images.blueMedicine.selector);
  }

  handleToolsObserver() {
    this.animations.tools = new TimelineMax();
    this.animations.tools.pause();
    this.animations.tools.from(this.images.tools.selector, 1.5, {
      y: "290px",
    });

    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        this.animations.tools,
        this.images.tools.selector,
        this.images.tools.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.images.tools.selector.style.willChange = "transform";
        this.images.tools.active = true;
      } else {
        this.images.tools.selector.style.willChange = "auto";
        this.images.tools.active = false;
      }
    });
    observer.observe(this.images.tools.selector);
  }

  handleIntroHandObserver() {
    this.setObserverAndListener(
      this.images.introHand,
      this.animations.introHand
    );
  }

  setObserverAndListener(object, animation) {
    this.scrollbar.addListener((event) =>
      this.handleTimelineProgressRelativeToScroll(
        animation,
        object.selector,
        object.active
      )
    );
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        object.selector.style.willChange = "transform";
        object.active = true;
      } else {
        object.selector.style.willChange = "auto";
        object.active = false;
      }
    });
    observer.observe(object.selector);
  }

  handleTimelineProgressRelativeToScroll(animation, watched, active) {
    if (!active) {
      return false;
    }

    TweenMax.killTweensOf(animation);

    const boundingClientRect = watched.getBoundingClientRect();
    const imageRelativeToScreen = Math.abs(
      boundingClientRect.y - window.innerHeight
    );

    const min = 0;
    const max = boundingClientRect.height + window.innerHeight;

    const range = max - min;
    const ratio = imageRelativeToScreen / range;

    animation.progress(ratio);
  }
}

export default Chapters;

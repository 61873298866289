import 'intersection-observer'
import SplitText from './vendor/SplitText.js'
import Router from './modules/Router'
import Cursor from './modules/Cursor'
import Home from './modules/Home'
import Chapters from './modules/Chapters'
import Shop from './modules/Shop'
import Project from './modules/Project'
import Contact from './modules/Contact'
import Mobile from './modules/Mobile'

const chapters = new Chapters()
const shop = new Shop()
const contact = new Contact()
const cursor = new Cursor()
const home = new Home()
const mobile = new Mobile()
const project = new Project()

const viewControllers = {
  home,
  chapters,
  shop,
  contact,
  project,
}

document.addEventListener('readystatechange', () => {
  document.body.classList.add('is-ready');
  document.documentElement.classList.add('is-ready');
})

const router = new Router(viewControllers, cursor)

mobile.init()
import helpers from "../helpers";
import transitionSettings from "../utils/transitionSettings";

export default class Home {
  init(route, direction) {
    this.route = route;
    this.direction = direction;
    this.$home = document.querySelector(".home");
    this.transitionEl = document.querySelector(".transition");
    this.playTransition();
    this.showTitle();
    this.constrolsVideo();

    this.$home.style.zIndex = 1;

    setTimeout(() => {
      this.showLink();
    }, 100);
  }

  leave(direction) {
    const duration = transitionSettings.duration;

    switch (direction) {
      case "left":
        TweenLite.fromTo(
          this.$home,
          duration,
          { x: 0 },
          { x: 300, ease: transitionSettings.ease },
          0
        );
        break;
      case "right":
        TweenLite.fromTo(
          this.$home,
          duration,
          { x: 0 },
          { x: -300, ease: transitionSettings.ease },
          0
        );
        break;
      case "top":
        TweenLite.fromTo(
          this.$home,
          duration,
          { y: 0 },
          { y: 300, ease: transitionSettings.ease },
          0
        );
        break;
      case "bottom":
        TweenLite.fromTo(
          this.$home,
          duration,
          { y: 0 },
          { y: -300, ease: transitionSettings.ease },
          0
        );
        break;
      default:
        TweenLite.fromTo(
          this.$home,
          duration,
          { y: 0 },
          { y: -300, ease: transitionSettings.ease },
          0
        );
        break;
    }
  }

  playTransition() {
    const duration = transitionSettings.duration;
    const width = window.innerWidth;
    const height = window.innerHeight;

    let timeline = new TimelineLite({
      onComplete: () => {
        this.onInitialAnimCompleted();
      },
    });

    //debug
    // timeline.timeScale(0.5)

    timeline.set(this.transitionEl, { display: "block" });

    switch (this.direction) {
      case "left":
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: -width, y: 0 },
          { x: width, ease: transitionSettings.ease }
        );
        break;
      case "right":
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: width, y: 0 },
          { x: -width, ease: transitionSettings.ease }
        );
        break;
      case "top":
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: 0, y: -height },
          { y: height, ease: transitionSettings.ease }
        );
        break;
      case "bottom":
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: 0, y: height },
          { y: -height, ease: transitionSettings.ease }
        );
        break;
      default:
        timeline.fromTo(
          this.transitionEl,
          duration,
          { x: 0, y: height },
          { y: -height, ease: transitionSettings.ease }
        );
        break;
    }

    timeline.set(this.$home, { width: "100vw" }, 0.3);
    // timeline.set(this.transitionEl, { display: 'none' });
  }

  onInitialAnimCompleted() {
    helpers.clearOldDom(this.route);
  }

  showTitle() {
    const $title = this.$home.querySelector(".home--title");
    const lines = [];

    const split = new SplitText($title, { type: "lines" });
    $title.innerHTML = "";
    split.lines.forEach((line) => {
      lines.push(line);
      const $hiddenWrap = document.createElement("div");
      $hiddenWrap.classList.add("hidden-wrap");
      $hiddenWrap.appendChild(line);
      $title.appendChild($hiddenWrap);
    });

    const $lines = $title.querySelectorAll(".hidden-wrap div");
    for (let i = 0; i < $lines.length; i++) {
      TweenMax.to($lines[i], 1, {
        y: 0,
        ease: Power3.easeOut,
        delay: 1 + i / 10,
      });
    }

    // TweenMax.to($lines, {
    //   duration: .5,
    //   y: 0,
    //   ease: Power1.easeOut,
    //   delay: 1,
    // });
  }

  constrolsVideo() {
    const bgVideo = this.$home.querySelector(".home--vid");
    var progressBar = this.$home.querySelector(".home--vid-progress");
    setTimeout(() => {
      bgVideo.style.opacity = 1;
    progressBar.style.transform = "scaleX(1)";

    }, 700);
    bgVideo.controls = false;
  
    bgVideo.addEventListener(
      "timeupdate",
      function () {
        var percentage = Math.floor(
          (100 / bgVideo.duration) * bgVideo.currentTime
        );

        progressBar.value = percentage;
      },
      false
    );
  }

  showLink() {
    const $link = this.$home.querySelector(".js-link");
    setTimeout(() => {
      $link.classList.add("transition-in");
    }, 1400);
  }
}

import helpers from '../helpers'
import transitionSettings from '../utils/transitionSettings';

export default class Project {

  init(route, direction) {
    this.route = route;
    this.transitionEl = document.querySelector('.transition');
    this.$project = document.querySelector('.project');

    this.playTransition();

    setTimeout(() => {
      this.showTitle()
      this.showText()
      this.showImage()
      this.showLink()
    }, 100);
  }

  leave(direction) {
    const duration = transitionSettings.duration;
    switch (direction) {
      case 'left':
        TweenLite.fromTo(this.$project, duration, { x: 0 }, { x: 300, ease: transitionSettings.ease }, 0);
        break;
      case 'right':
        TweenLite.fromTo(this.$project, duration, { x: 0 }, { x: -300, ease: transitionSettings.ease }, 0);
        break;
      case 'top':
        TweenLite.fromTo(this.$project, duration, { y: 0 }, { y: 300, ease: transitionSettings.ease }, 0);
        break;
      case 'bottom':
        TweenLite.fromTo(this.$project, duration, { y: 0 }, { y: -300, ease: transitionSettings.ease }, 0);
        break;
        default:
          TweenLite.fromTo(this.$project, duration, { y: 0 }, { y: -300, ease: transitionSettings.ease }, 0);
        break;
    }
  }

  playTransition() {
    const duration = transitionSettings.duration;
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (!this.direction && this.from === 'previous') {
      this.direction = 'left';
    }
    if (!this.direction && this.from === 'next') {
      this.direction = 'right';
    }

    let timeline = new TimelineLite({ onComplete: () => { this.onInitialAnimCompleted() } });
    timeline.set(this.transitionEl, { display: 'block' });

    switch (this.direction) {
      case 'left':
          timeline.fromTo(this.transitionEl, duration, { x: -width, y: 0 }, { x: width, ease: transitionSettings.ease });
        break;
      case 'right':
        timeline.fromTo(this.transitionEl, duration, { x: width, y: 0 }, { x: -width, ease: transitionSettings.ease });
        break;
      case 'top':
        timeline.fromTo(this.transitionEl, duration, { x: 0, y: -height }, { y: height, ease: transitionSettings.ease });
        break;
      case 'bottom':
        timeline.fromTo(this.transitionEl, duration, { x: 0, y: height }, { y: -height, ease: transitionSettings.ease }, 0);
        timeline.fromTo(this.$project, duration, { y: 300 }, { y: 0, ease: transitionSettings.ease }, 0);
        break;
        default:
          timeline.fromTo(this.transitionEl, duration, { x: 0, y: height }, { y: -height, ease: transitionSettings.ease });
          timeline.fromTo(this.$project, duration, { y: 300 }, { y: 0, ease: transitionSettings.ease }, 0);
        break;
    }

    timeline.set(this.$project, { autoAlpha: 1, delay: 0.35 }, 0.45)
    timeline.set(this.transitionEl, { display: 'none' });
  }

  showTitle() {
    const title = this.$project.querySelector('.js-title');
    const splitTitle = new SplitText(title, { type: 'chars' });
    TweenMax.staggerFrom(splitTitle.chars, .5, { y: '100%', ease: Power1.easeOut, delay: 1 }, .03)
  }

  showText() {
    let paragraph = this.$project.querySelector('.js-paragraph');
    const splitText = new SplitText(paragraph, { type: 'lines' })

    let tl = new TimelineLite({ onComplete: () => {
      paragraph.innerHTML = splitText._originals[0];
    } })

    tl.staggerFrom(splitText.lines, .5, { y: '100%', opacity: 0, ease: Power1.easeOut, delay: 1.2 }, .03)
  }

  showImage() {
    const $image = this.$project.querySelectorAll('.js-image')
    // TweenMax.staggerFrom($image, 1, { opacity: 0, ease: Power1.easeOut, delay: 1.2 })
  }

  showLink() {
    const $link = this.$project.querySelector('.js-link');
    setTimeout(() => {
      $link.classList.add('transition-in');
    }, 1400);
  }

  onInitialAnimCompleted() {
    helpers.clearOldDom(this.route)
  }
}
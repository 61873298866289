const helpers = {}

helpers.clearOldDom = (route, secondChild)=> {
  const $appNodes = document.querySelector('.app').childNodes
  $appNodes.forEach($node => {
    if ($node.firstChild) {
      if ($node.classList.contains('smooth-scroll')) {
        if (!$node.classList.contains(route)) {
          $node.parentNode.removeChild($node)
        }
      } else {
        if (!$node.firstChild.classList.contains(route)) {
          $node.parentNode.removeChild($node)
        }
      }
    }
  })
}

export default helpers

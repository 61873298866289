module.exports={
    "route": {
        "home": {
            "previous": "",
            "next": {
                "string": "Chapitre 1",
                "slug": "chapter-1"
            },
            "current": {
                "string": "La thyroïde",
                "slug": "home"
            },
            "top": {
                "string": "Le projet",
                "slug": "project"
            }
        },
        "shop": {
            "previous": "",
            "next": "",
            "top": {
                "string": "Le projet",
                "slug": "project"
            }
        },
        "project": {
            "previous": "",
            "next": "",
            "current": {
                "string": "La thyroïde"
            },
            "top": {
                "string": "Prévention",
                "slug": "prevention"
            }
        },
        "prevention": {
            "previous": "",
            "current": {
                "string": "La thyroïde"
            },
            "next": "",
            "top": {
                "string": "Le projet",
                "slug": "project"
            }
        },
        "chapter-1": {
            "previous": {
                "string": "Accueil",
                "slug": "home"
            },
            "current": {
                "string": "Chapitre 1",
                "slug": "chapter-1"
            },
            "next": {
                "string": "Chapitre 2",
                "slug": "chapter-2"
            },
            "top": {
                "string": "Le projet",
                "slug": "project"
            }
        },
        "chapter-2": {
            "previous": {
                "string": "Chapitre 1",
                "slug": "chapter-1"
            },
            "current": {
                "string": "Chapitre 2",
                "slug": "chjapter-2"
            },
            "next": {
                "string": "Chapitre 3",
                "slug": "chapter-3"
            },
            "top": {
                "string": "Le projet",
                "slug": "project"
            }
        },
        "chapter-3": {
            "previous": {
                "string": "Chapitre 2",
                "slug": "chapter-2"
            },
            "current": {
                "string": "Chapitre 3",
                "slug": "chapter-3"
            },
            "next": {
                "string": "Accueil",
                "slug": "home"
            },
            "top": {
                "string": "Le projet",
                "slug": "project"
            }
        }
    }
}
export default class Mobile {

  init() {
    this.playButton = document.querySelector('.mobile-container .aside-left--play-button')
    this.videoMobile = document.querySelector('.mobile-vid-full')
    this.initEventListeners()
    this.initHeightForMobile()
  }

  initEventListeners() {
    this.playButton.addEventListener('click', () => this.triggerVideo())
    this.videoMobile.addEventListener('click', () => this.closeVideo())
  }

  initHeightForMobile() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    });
  }

  triggerVideo() {
    this.videoMobile.style.display = 'block'
    this.videoMobile.play()
  }

  closeVideo() {
    this.videoMobile.style.display = 'none'
    this.videoMobile.pause()
    this.videoMobile.currentTime = 0
  }
}
import lerp from "../utils/lerp";
import gsap from "gsap";

export default class Slider {
  constructor(className) {
    this.bind();
    this.className = className;
    this.elementsDom = document.querySelectorAll(`${this.className}`);
    this.elements = [];
    this.observers = "";
    this.timelines = [];

    this.firstSlider = document.querySelector(".hypothyroïdie");
    this.firstSliderWrapper = this.firstSlider.querySelector(
      ".symptoms--wrapper"
    );
    this.secondSlider = document.querySelector(".hyperthyroid");
    this.secondSliderWrapper = this.secondSlider.querySelector(
      ".symptoms--wrapper"
    );

    this.scrolledContent = document.querySelector(".scroll-content");

    this.onX = 0;
    this.offX = 0;

    this.currentX = 0;
    this.lastX = 0;

    this.onX2 = 0;
    this.offX2 = 0;

    this.currentX2 = 0;
    this.lastX2 = 0;

    this.min = -11500;
    this.max = 900;

    this.speed = 1.5;
    this.ease = 0.1;
    this.velocity = 25;

    this.isDragging = false;
    this.target = null;
    this.currentSlider;

    this.elementsDom.forEach((element) => {
      this.elements.push({
        selector: element,
        sliderContainer: element.querySelector(".chapter--symptoms"),
        slider: element.querySelector(".chapter--symptoms ul"),
        slides: element.querySelectorAll(".chapter--symptom"),
        progressEl: element.querySelector(".chapter--slider__progress-value"),
        progressValueEl: element.querySelector(".active-slide"),
        progress: 0,
      });
    });
    this.setup();
  }

  bind() {
    ["run", "setPos", "on", "off"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  setup() {
    this.observer();
    this.setupTicker();
    this.commonEvents();
    this.elements.forEach((element) => {
      this.slidersEvents(element);
    });
  }

  observer() {
    this.observers = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
              entry.target.parentNode.parentNode.parentNode.classList.add(
                "is-visible"
            );
            console.log(entry.target.parentNode.parentNode.parentNode)
            let currentIndex = entry.target.parentNode.parentNode.parentNode.parentNode.querySelector(
              ".active-slide"
            );
            currentIndex.innerHTML = entry.target.dataset.symptom;
          } else {
              entry.target.parentNode.parentNode.parentNode.classList.remove(
                "is-visible"
            );
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    this.elements.forEach((element, i) => {
      for (let k = 0; k < element.slides.length; k++) {
        this.observers.observe(element.slides[k]);
      }
    });
  }

  slidersEvents(slider) {
    slider.slider.addEventListener("mousedown", this.on, false);
  }

  commonEvents() {
    window.addEventListener("mousemove", this.setPos, { passive: true });
    window.addEventListener("mouseup", this.off, false);
  }

  setPos(e) {
    if (!this.isDragging) return;
    this.currentSlider === 1
      ? (this.currentX = gsap.utils.clamp(
          this.min,
          this.max,
          this.offX + (e.clientX - this.onX) * this.speed
        ))
      : gsap.utils.clamp(
          this.min,
          this.max,
          (this.currentX2 = this.offX2 + (e.clientX - this.onX2) * this.speed)
        );
  }

  on(e) {
    this.target = e.target;
    this.target.classList.contains("hypothyroïdie")
      ? (this.currentSlider = 1)
      : (this.currentSlider = 2);
    this.isDragging = true;
    this.currentSlider === 1 ? (this.onX = e.clientX) : (this.onX2 = e.clientX);
  }

  off() {
    this.isDragging = false;
    this.offX = this.currentX;
    this.offX2 = this.currentX2;
  }

  run() {
    let scrollSlider1 = this.firstSlider.getBoundingClientRect().y / 5;
    let scrollSlider2 = this.secondSlider.getBoundingClientRect().y / 5;

    this.lastX = lerp(this.lastX, this.currentX, this.ease);
    this.lastX = Math.floor(this.lastX * 100) / 100;

    this.lastX2 = lerp(this.lastX2, this.currentX2, this.ease);
    this.lastX2 = Math.floor(this.lastX2 * 100) / 100;

    const sd = this.currentX - this.lastX;
    const sd2 = this.currentX2 - this.lastX2;

    const acc = sd / window.innerWidth;
    const acc2 = sd2 / window.innerWidth;
    let velo = +acc;
    let velo2 = +acc2;


    this.firstSlider.style.transform = `translate3d(${
      this.lastX + scrollSlider1
    }px, 0, 0) skewX(${velo * this.velocity}deg)`;
    this.secondSlider.style.transform = `translate3d(${
      this.lastX2 + scrollSlider2
    }px, 0, 0) skewX(${velo2 * this.velocity}deg)`;
  }

  setupTicker() {
    TweenLite.ticker.addEventListener("tick", () => {
      this._tickHandler();
    });
  }

  _tickHandler() {
    this.run();
  }
}

export default class Intro {

  init(callback) {
    this.callback = callback
    this.container = document.querySelector('.intro')
    this.text = document.querySelectorAll('.intro p')
    this.button = document.querySelector('.intro__cta')
    this.timeout = ''
    this.hiding = false

    this.container.classList.remove('intro--hidden')

    this.setupSplitText();
    this.revealAnim()
  }

  setupSplitText() {
    this.words = [];
    this.splittedText = new SplitText(this.text, { type: 'lines' });

    for (let index = 0; index < this.splittedText.lines.length; index++) {
      const element = this.splittedText.lines[index];
      element.style.overflow = 'hidden';

      let words = new SplitText(element, { type: 'words' }).words;

      for (let i = 0; i < words.length; i++) {
        const element = words[i];
        this.words.push(element);
      }
    }
  }

  revealAnim() {
    let timeline = new TimelineLite();

    timeline.staggerFromTo(this.words, 1.5, { autoAlpha: 0, y: 50 }, { autoAlpha: 1, y: 0, ease: Power4.easeOut }, 0.08, 1.8);
    timeline.fromTo(this.button, 1.5, { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0, ease: Power4.easeOut }, 4.5);

    setTimeout(() => { this.enableDiscover() }, 5500);
  }

  enableDiscover() {
    this.button.classList.add('is-active')
    this.button.addEventListener('click', () => {
      if (this.hiding) return;

      this.hideAnim();
    })
  }

  hideAnim() {
    this.hiding = true

    const timeline = new TimelineMax({ onComplete: () => this.callback() });
    
    timeline.staggerTo(this.splittedText.lines, 1, { autoAlpha: 0, y: -50, ease: Power3.easeIn }, 0.08);
    timeline.to(this.button, 0.8, { autoAlpha: 0, y: -20, ease: Power3.easeIn }, 0.5);
    timeline.to(this.container, 0.3, { autoAlpha: 0 }, 1.5);
    
    // setTimeout(() => { this.button.classList.remove('is-active'); }, 0.3);
  }
}
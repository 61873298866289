export default class Header {
  constructor() {
    this.$header = document.querySelector(".header");
    this.volume = true;
    this.volumeBarsContainer = document.querySelectorAll(
      ".aside-right--sound-button"
    );
    this.volumeBars = document.querySelectorAll(
      ".aside-right--sound-button .bar"
    );
    this.audioBg = document.querySelector(".background-sound");
    this.audioOff = document.querySelector(".background-sound--off");
    this.body = document.querySelector("body");
    this.split = new SplitText(`.nav--title a .link-content`, {
      type: "chars",
    });
    this.splitSubContent = new SplitText(`.nav--title a .link-subcontent`, {
      type: "chars",
    });
    this.timelineBurgerIn = "";
    this.closeMenu = this.closeMenu.bind(this);

    this.ui = {
      $headerBurger: document.querySelector(".header--burger"),
      $navCross: document.querySelector(".nav--cross"),
      $nav: document.querySelector(".nav"),
      $upperBar: document.querySelector(".nav .bar-1"),
      $lowerBar: document.querySelector(".nav .bar-2"),
      $internalLinks: document.querySelectorAll(".nav .route-link"),
    };

    this.setTimelineBurgerIn();
    this.setTimelineBurgerOut();
    this.startIntroAnimation();
    this.setupEventListeners();
    this.initEventsForVolumeControl();
  }

  startIntroAnimation() {
    setTimeout(() => {
      setTimeout(() => {
        this.animateDot();
      }, 0);
      setTimeout(() => {
        this.animateTitle();
      }, 100);
      setTimeout(() => {
        this.animateShop();
      }, 500);
      setTimeout(() => {
        this.animateBurger();
      }, 500);
    }, 1000);
  }

  animateDot() {
    const $dot = this.$header.querySelector(".header--dot");

    TweenMax.to($dot, 1, { scale: 1, ease: Elastic.easeOut });
  }

  animateBurger() {
    const $burger = this.$header.querySelector(".header--burger");
    const $upper = $burger.querySelector(".header--burger--upper-bun");
    const $lower = $burger.querySelector(".header--burger--lower-bun");

    let timeline = new TimelineMax();

    timeline.to($upper, 0.8, { scaleX: 1, ease: Power3.easeInOut });
    timeline.to($lower, 0.8, { scaleX: 1, ease: Power3.easeInOut }, 0.2);
  }

  animateTitle() {
    const $title = this.$header.querySelector(".header--title");
    const $bar = $title.querySelector(".header--title--bar");
    const $text = $title.querySelector(".header--title--text");

    let timeline = new TimelineLite();

    timeline.to($bar, 0.8, { scaleX: 1, ease: Power3.easeOut }, 0);
    timeline.to($text, 0.8, { y: 0, ease: Power3.easeOut }, 0.2);
  }

  animateShop() {
    const $shop = this.$header.querySelector(".header--shop");
    const $text = $shop.querySelector(".header--shop--text");
    const $dot = $shop.querySelector(".header--shop--dot");

    TweenMax.to($text, 0.8, { y: 0, ease: Power3.easeOut });
    TweenMax.to($dot, 0.5, { opacity: 1, ease: Power1.easeOut, delay: 0.7 });
  }

  setTimelineBurgerIn() {
    const navElements = document.querySelectorAll(".nav--element");
    const navLinks = document.querySelectorAll(".nav--links .nav--link");
    const navFilter = document.querySelector(".nav--filter");

    this.timelineBurgerIn = new TimelineMax({ paused: true });
    this.timelineBurgerIn.timeScale(1.1);

    this.timelineBurgerIn.to(
      this.ui.$nav,
      1.6,
      { y: "100%", ease: Expo.easeInOut },
      0
    );
    this.timelineBurgerIn.to(
      navFilter,
      1.6,
      { autoAlpha: 0.6, ease: Expo.easeInOut },
      0
    );
    this.timelineBurgerIn.from(
      this.ui.$upperBar,
      1,
      { scaleX: 0, ease: Power3.easeInOut },
      1
    );
    this.timelineBurgerIn.from(
      this.ui.$lowerBar,
      1,
      { scaleX: 0, ease: Power3.easeInOut },
      1
    );
    this.timelineBurgerIn.from(
      this.ui.$upperBar,
      1,
      { rotation: 0, ease: Power3.easeInOut },
      1.7
    );
    this.timelineBurgerIn.from(
      this.ui.$lowerBar,
      1,
      { rotation: 0, ease: Power3.easeInOut },
      1.7
    );

    navElements.forEach((element, index) => {
      const title = element.querySelectorAll(".nav--title .link-content div");
      const number = element.querySelector(".nav--number a");

      this.timelineBurgerIn.staggerFrom(
        title,
        1 - 0.05 * index,
        { y: "100%", ease: Power3.easeOut },
        0.02,
        1 + 0.15 * index
      );
      this.timelineBurgerIn.fromTo(
        number,
        1 - 0.05 * index,
        { y: "100%", autoAlpha: 0 },
        { y: "0%", autoAlpha: 1, ease: Power3.easeOut },
        1.5 + 0.15 * index
      );
    });

    this.timelineBurgerIn.staggerFromTo(
      navLinks,
      1,
      { y: "100%", autoAlpha: 0 },
      { y: "0%", autoAlpha: 1, ease: Power3.easeOut },
      0.1,
      1.7
    );
  }

  setTimelineBurgerOut() {}

  openMenu() {
    this.timelineBurgerIn.timeScale(1.1);
    this.timelineBurgerIn.progress(0);
    this.timelineBurgerIn.play();
    this.body.classList.add("js-nav-is-opened");

    const $burger = this.$header.querySelector(".header--burger");
    const $upper = $burger.querySelector(".header--burger--upper-bun");
    const $lower = $burger.querySelector(".header--burger--lower-bun");

    TweenMax.to($upper, 0.8, {
      scaleX: 0,
      ease: Power3.easeOut,
      transformOrigin: "right",
    });
    TweenMax.to($lower, 0.8, {
      scaleX: 0,
      ease: Power3.easeOut,
      transformOrigin: "right",
    });
  }

  enterBurger() {
    const $burger = this.$header.querySelector(".header--burger");
    const $lower = $burger.querySelector(".header--burger--lower-bun");

    // TweenMax.to($lower, 0.8, { scaleX: 1.6, ease: Power3.easeOut,transformOrigin:"right" } )
  }

  leaveBurger() {
    const $burger = this.$header.querySelector(".header--burger");
    const $lower = $burger.querySelector(".header--burger--lower-bun");

    // TweenMax.to($lower, 0.8, { scaleX: 1, ease: Power3.easeOut } )
  }

  closeMenu() {
    let tweenObj = { progress: 1 };

    this.timelineBurgerIn.timeScale(1);
    this.timelineBurgerIn.progress(tweenObj.progress);
    this.timelineBurgerIn.pause();

    TweenMax.to(tweenObj, 2.2, {
      progress: 0,
      onUpdate: () => {
        this.timelineBurgerIn.progress(tweenObj.progress.toFixed(3));
      },
      ease: Power3.EaseOut,
    });

    this.body.classList.remove("js-nav-is-opened");

    const $burger = this.$header.querySelector(".header--burger");
    const $upper = $burger.querySelector(".header--burger--upper-bun");
    const $lower = $burger.querySelector(".header--burger--lower-bun");

    TweenMax.to($upper, 0.8, {
      scaleX: 1,
      ease: Power3.easeOut,
      transformOrigin: "left",
      delay: 1.5,
    });
    TweenMax.to($lower, 0.8, {
      scaleX: 1,
      ease: Power3.easeOut,
      transformOrigin: "left",
      delay: 1.7,
    });
  }

  closeMenuLink() {
    let tweenObj = { progress: 1 };

    this.timelineBurgerIn.progress(tweenObj.progress);
    this.timelineBurgerIn.pause();

    TweenMax.to(tweenObj, 0.1, {
      progress: 0,
      onUpdate: () => {
        this.timelineBurgerIn.progress(tweenObj.progress.toFixed(3));
      },
      ease: Power3.EaseOut,
      delay: 0.4,
    });

    this.body.classList.remove("js-nav-is-opened");
  }

  titlesEnter(e) {
    let currentSplit = e.currentTarget.querySelectorAll(".link-content div");
    let currentSplitSub = e.currentTarget.querySelectorAll(
      ".link-subcontent div"
    );

    let timeline = new TimelineLite();
    timeline.staggerTo(
      currentSplit,
      1,
      { y: "100%", ease: Power3.easeOut },
      0.02,
      0
    );
    timeline.staggerTo(
      currentSplitSub,
      1,
      { y: "100%", ease: Power3.easeOut },
      0.02,
      0
    );
  }

  titlesLeave(e) {
    let currentSplit = e.currentTarget.querySelectorAll(".link-content div");
    let currentSplitSub = e.currentTarget.querySelectorAll(
      ".link-subcontent div"
    );

    let timeline = new TimelineLite();
    timeline.staggerTo(
      currentSplit,
      1,
      { y: "0%", ease: Power3.easeOut },
      0.02,
      0
    );
    timeline.staggerTo(
      currentSplitSub,
      1,
      { y: "0%", ease: Power3.easeOut },
      0.02,
      0
    );
  }

  setupEventListeners() {
    // Burger
    this.ui.$headerBurger.addEventListener("mousedown", () => {
      this.openMenu();
    });

    // Over Burger
    this.ui.$headerBurger.addEventListener("mouseenter", () => {
      this.enterBurger();
    });

    // Leaving Burger

    this.ui.$headerBurger.addEventListener("mouseleave", () => {
      this.leaveBurger();
    });

    // Close cross
    this.ui.$navCross.addEventListener("mousedown", () => {
      this.closeMenu();
    });

    // Keypress
    document.addEventListener("keyup", (evt) => {
      if (
        evt.key === "Escape" &&
        this.body.classList.contains("js-nav-is-opened")
      ) {
        this.closeMenu();
      }
    });

    this.ui.$internalLinks.forEach(($link) => {
      $link.addEventListener("click", (e) => {
        const pathArray = window.location.pathname.split("/");
        const current = pathArray[1] || "home";
        const targetUrl = e.currentTarget.dataset.url;

        if (targetUrl === current) {
          this.closeMenu();
        } else {
          this.closeMenuLink();
        }
        this.closeMenu();
      });

      $link.addEventListener("mouseenter", (e) => {
        // this.titlesEnter(e);
      });

      $link.addEventListener("mouseleave", (e) => {
        // this.titlesLeave(e);
      });
    });
  }

  initEventsForVolumeControl() {
    const volumeControl = document.querySelector(".aside-right--sound-button");
    volumeControl.addEventListener("click", () => {
      if (this.volume) {
        let timeline = new TimelineLite();
        timeline.to(this.volumeBarsContainer, 1, {
          scaleY: 0,
          ease: Power3.easeOut,
        });
        timeline.to(this.volumeBarsContainer, 1, {
          scaleY: 1,
          ease: Power3.easeOut,
        });
        this.volumeBars.forEach((bar) => {
          setTimeout(() => {
            bar.classList.add("bar--paused");
          }, 1000);
          if (this.audioOff) {
            TweenMax.to(this.audioOff, 1, {
              volume: 0,
              ease: Power3.easeOut,
            });
          }
          TweenMax.to(this.audioBg, 1, {
            volume: 0,
            ease: Power3.easeOut,
          });
          this.volume = false;
        });
      } else {
        let timeline = new TimelineLite();
        timeline.to(this.volumeBarsContainer, 1, {
          scaleY: 0,
          ease: Power3.easeOut,
        });
        timeline.to(this.volumeBarsContainer, 1, {
          scaleY: 1,
          ease: Power3.easeOut,
        });
        this.volumeBars.forEach((bar) => {
          setTimeout(() => {
            bar.classList.remove("bar--paused");
          }, 1000);
          if (this.audioOff) {
            TweenMax.to(this.audioOff, 1, {
              volume: 1,
              ease: Power3.easeIn,
            });
          }
          TweenMax.to(this.audioBg, 1, {
            volume: 1,
            ease: Power3.easeIn,
          });
          this.volume = true;
        });
      }
    });
  }
}

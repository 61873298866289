export default class Asides {
  constructor() {
    this.$asideLeft = document.querySelector(".aside-left");
    this.$asideRight = document.querySelector(".aside-right");

    setTimeout(() => {
      setTimeout(() => {
        this.animatePlayButton();
      }, 0);
      setTimeout(() => {
        this.animateChapter();
      }, 0);
      setTimeout(() => {
        this.animateSoundButton();
      }, 0);
    }, 500);
  }

  animatePlayButton() {
    const $playButton = this.$asideLeft.querySelector(
      ".aside-left--play-button"
    );
    const $text = $playButton.querySelector(
      ".aside-left--play-button--text")

    const $circle = $playButton.querySelector(
      ".aside-left--play-button circle"
    );
    const $triangle = $playButton.querySelector(
      ".aside-left--play-button .triangle"
    );

    setTimeout(() => {
      $playButton.classList.add("is-active");
    }, 500);

    TweenMax.to($circle, 1, {
      strokeDashoffset: "370px",
      ease: Power3.easeOut,
    });
    TweenMax.to($triangle, 0.5, {
      opacity: 1,
      ease: Power1.easeOut,
      delay: 0.2,
    });
    TweenMax.to($text, 1, {
      alpha: 1
    })
  }

  animateChapter() {
    const $leftText = document.querySelector(".aside-left--home--text");
    const $leftDot = document.querySelector(".aside-left--home--dot");
    const $rightText = document.querySelector(".aside-right--chapter--text");
    const $rightDot = document.querySelector(".aside-right--chapter--dot");

    setTimeout(() => {
      $leftText.style.opacity = "1";
      $leftDot.style.opacity = "1";
      $rightText.style.opacity = "1";
      $rightDot.style.opacity = "1";
    }, 500);
  }

  animateSoundButton() {
    const $soundButton = this.$asideRight.querySelectorAll(
      ".aside-right--sound-button"
    );
    setTimeout(() => {
      TweenMax.to($soundButton, 1, {
        scaleY: 1,
        ease: Power3.easeOut,
        delay: 1,
      });
    }, 500);
  }
}

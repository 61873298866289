export default class Video {

  init() {
    this.video = document.querySelector('.vid-full')
    this.videoPlayer = this.video.querySelector('.vid-full__video')
    this.ctaPlay = document.querySelectorAll('.aside-left .flex .aside-left--play-button, .trigger-movie')
    this.backgroundAudio = document.querySelector('.background-sound')
    this.offAudio = document.querySelector('.background-sound--off')
    this.initEventListeners()
  }

  linkInChapter() {
    this.linkChapter = document.querySelectorAll('.trigger-movie')

    for (let i = 0; i < this.linkChapter.length; i++) {
      const element = this.linkChapter[i];
      element.addEventListener('click', () => {
        this.updateCursorToCross()
        this.video.classList.add('playing')
        this.offAudio.pause()
        this.backgroundAudio.pause()
        TweenMax.to(this.video, 1.5, {
          height: '100vh',
          ease: Circ.easeInOut,
          onComplete: () => {
            this.videoPlayer.play()
          }
        })
      })
    }

  }

  initEventListeners() {
    this.ctaPlay.forEach(cta => {
      cta.addEventListener('click', () => {
        this.updateCursorToCross()
        this.video.classList.add('playing')
        this.offAudio.pause()
        this.backgroundAudio.pause()
        TweenMax.to(this.video, 1.5, {
          height: '100vh',
          ease: Power4.easeInOut,
          onComplete: () => {
            this.videoPlayer.play()
          }
        })
      })
    })

    this.video.addEventListener('click', () => {
      this.updateCursorToPointer()
      this.videoPlayer.pause()
      if (this.pullRoute() === '' || this.pullRoute() === 'home') {
        this.offAudio.play()
      }
      this.backgroundAudio.play()
      TweenMax.to(this.video, 1.5, {
        height: 0,
        ease: Power4.easeInOut,
        onComplete: () => {
          this.videoPlayer.currentTime = 0
          this.video.classList.remove('playing')
        }
      })
    })
    this.videoPlayer.addEventListener('ended', () => {
      this.videoEnded()
    } )
  }

  pullRoute() {
    return window.location.href.split("/")[3]
  }

  videoEnded() {
    this.updateCursorToPointer()
    this.videoPlayer.pause()
    this.backgroundAudio.play()
    TweenMax.to(this.video, 1.5, {
      height: '0',
      ease: Power4.easeInOut,
    onComplete: () => {
      this.videoPlayer.currentTime = 0
      this.video.classList.remove('playing')
    }
    })
  }

  updateCursorToCross() {
    const cursorCircleSvg = document.querySelector('.js-cursor svg.circle circle')
    const cursorCrossSvg = document.querySelectorAll('.js-cursor svg.cross path')
    const pointer = document.querySelector('.js-pointer')
    const timeline = new TimelineMax()

    TweenMax.killTweensOf(cursorCircleSvg)
    TweenMax.killTweensOf(cursorCrossSvg)
    TweenMax.killTweensOf(pointer)

    timeline.to(pointer, 0.5, {
      autoAlpha: 0,
      ease: Power1.easeOut
    }, 'hide')

    timeline.to(cursorCircleSvg, 1.5, {
      strokeDashoffset: '500px',
      ease: Circ.easeInOut
    }, 'hide')

    timeline.to(cursorCrossSvg[0], 0.5, {
      strokeDashoffset: '500px',
    })

    timeline.to(cursorCrossSvg[1], 0.5, {
      strokeDashoffset: '0',
    }, '-=0.35')
  }

  updateCursorToPointer() {
    const cursorCircleSvg = document.querySelector('.js-cursor svg.circle circle')
    const cursorCrossSvg = document.querySelectorAll('.js-cursor svg.cross path')
    const pointer = document.querySelector('.js-pointer')
    const timeline = new TimelineMax()

    TweenMax.killTweensOf(cursorCircleSvg)
    TweenMax.killTweensOf(cursorCrossSvg)
    TweenMax.killTweensOf(pointer)

    timeline.to(cursorCrossSvg[1], 0.5, {
      strokeDashoffset: '500px',
    })

    timeline.to(cursorCrossSvg[0], 0.5, {
      strokeDashoffset: '250px',
    }, '-=0.35')

    timeline.to(cursorCircleSvg, 1.5, {
      strokeDashoffset: '0',
      ease: Circ.easeInOut
    }, 'hide')

    timeline.to(pointer, 0.5, {
      autoAlpha: 1,
      ease: Power1.easeOut
    }, 'hide')
  }
}